import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Overview`}</h2>
    <p>{`This article lists all publication results that are available in the CoreMedia Content Cloud`}</p>
    <h2>{`Publication Result Codes`}</h2>
    <ul>
      <li parentName="ul">{`0 NoOp, // no action needed to bring this resource up to date`}</li>
      <li parentName="ul">{`1 ContentPublished, // some version of this document was published`}</li>
      <li parentName="ul">{`2 Created, // this folder was created`}</li>
      <li parentName="ul">{`3 Moved, // this resource has been moved to a new parent`}</li>
      <li parentName="ul">{`4 Deleted, // this resource has been deleted and archived`}</li>
      <li parentName="ul">{`5 Unpublished, // this resource has been unpublished`}</li>
      <li parentName="ul">{`6 INFO, // all informational messages have a smaller code`}</li>
      <li parentName="ul">{`7 AlreadyPublished, // this version is already online`}</li>
      <li parentName="ul">{`8 LaterVersion, // a later version is already online`}</li>
      <li parentName="ul">{`9 WARNING, // all warning codes are in INFO, WARNING`}</li>
      <li parentName="ul">{`10 DoesNotExist, // the submitted resource/version does not exist on the Content Server`}</li>
      <li parentName="ul">{`11 PlaceNotApproved, // the place of this resource is not approved`}</li>
      <li parentName="ul">{`12 VersionNotApproved, // this version is not approved`}</li>
      <li parentName="ul">{`13 SelectVersion, // cannot publish new document without version`}</li>
      <li parentName="ul">{`14 NoApprovedVersion, // this document has no approved version to publish`}</li>
      <li parentName="ul">{`15 AlreadyDeleted, // this resource could not be published as it was already deleted`}</li>
      <li parentName="ul">{`16 LinkFailed, // an internal link of this document could not be published`}</li>
      <li parentName="ul">{`17 CreateParentFailed, // could not create parent folder`}</li>
      <li parentName="ul">{`18 TargetNameConflict, // this resource would name-clash with another resource`}</li>
      <li parentName="ul">{`19 ResourceNameChanged, // this resource changed its name while being published`}</li>
      <li parentName="ul">{`20 NoRightToPublish, // user is not allowed to publish this resource`}</li>
      <li parentName="ul">{`21 CannotUnpublishBecauseOfLink, // unpublishing this resource would destroy the referential integrity`}</li>
      <li parentName="ul">{`22 CannotPublishArchived, // archived resources cannot be published`}</li>
      <li parentName="ul">{`23 Orphaned, // some children (live) would be left over if we deleted this folder`}</li>
      <li parentName="ul">{`24 MustDeleteChild, // this child (production) must be deleted, too`}</li>
      <li parentName="ul">{`25 NotBelowBaseFolder, // this resource is not located below the required base folder`}</li>
      <li parentName="ul">{`26 ERROR, // all error codes are in WARNING, ERROR`}</li>
      <li parentName="ul">{`27 RESULT_COUNT // THE VALUE OF THIS MEMBER IS EQUAL TO THE NUMBER OF MEMBERS EXCEPT THIS ONE`}</li>
    </ul>
    <p>{`See the class `}<inlineCode parentName="p">{`PublicationResultCode`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class PublicationResultCode implements org.omg.CORBA.portable.IDLEntity
{
  private        int __value;
  private static int __size = 28;
  private static hox.corem.corba.PublicationResultCode[] __array = new hox.corem.corba.PublicationResultCode [__size];

  public static final int _NoOp = 0;
  public static final hox.corem.corba.PublicationResultCode NoOp = new hox.corem.corba.PublicationResultCode(_NoOp);
  public static final int _ContentPublished = 1;
  public static final hox.corem.corba.PublicationResultCode ContentPublished = new hox.corem.corba.PublicationResultCode(_ContentPublished);
  public static final int _Created
  ...
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      